@import "vars";

.mr-24{
  margin-right: 24px;
}
.mb-32{
  margin-bottom: 32px;
}
.form-tabs{
  margin-bottom: 16px;
  .form-tabs__item{
    @include font($font, 400, 16px, 24px, $main);
    border: 1px solid rgba(0, 16, 61, 0.12);
    border-radius: 16px;
    padding: 4px 16px;
    margin-right: 8px;
    &.active{
      background: #05458A;
      border-radius: 16px;
      color: white;
    }
  }
}
.input-group{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
  width: 100%;
  max-width: 600px;
  margin-bottom: 48px;
}
.form-title{
  @include font($font, 600, 20px, 32px, $text-color);
  margin-bottom: 16px;
}
.contact-nbr{
  @include font($font, 600, 16px, 24px, $text-color);
  margin-bottom: 12px;
}
.input-label{
  @include font($font, 400, 12px, 24px, $text-color);
  margin-left: 12px;
}
.organisation-description{
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 4;
  grid-row-end: 5;
}
.form-line{
  display: block;
  width: 100%;
  height: 1px;
  background: rgba(0, 16, 61, 0.12);
  margin-bottom: 16px;
}









/* FORMS */

textarea,
input[type="text"],
input[type="tel"],
input[type="number"],
input[type="email"],
input[type="search"],
input[type="password"], {
  -moz-appearance:none;
  -webkit-appearance: none;
  appearance: none;
  &:focus {
    outline: none;
  }
}
select:focus {
  outline: none;
}
.input-field{
  @include font($font, 400, 14px, 24px, $text-color);
  width: 100%;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 8px;
  padding: 11px 15px;
  &:focus-visible{
    outline: none;
  }
}
.is-invalid,
.invalid{
  border: 1px solid rgba(237, 10, 52, 1);
}
.field-error{
  @include font($font, 400, 12px, 24px, rgba(237, 10, 52, 1));
}
.text_input{
  min-height: 72px;
  resize: none;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
  }

}
.organisation__form-title{
  @include font($font, 600, 24px, 40px, $main);
  display: flex;
  margin-right: 32px;
}
.back-arrow{
  background: url("../img/Arrow.svg") #ECEDF0 center no-repeat;
  display: block;
  width: 40px;
  height: 40px;
  flex: none;
  background-size: 24px;
  margin-right: 8px;
  border-radius: 50%;
}
.information-block,
.contacts_block-wrap,
.targets_block {
  display: none;
}

.show-block{
  display: block!important;
}
.organisation-form__send{
  display: flex;
  @include font($font, 400, 16px, 24px, white);
  padding: 8px 12px;
  border: none;
  background: #0DC268;
  border-radius: 8px;
  transition: 0.3s;
  text-decoration: none;
  width: fit-content;
  box-sizing: border-box;
  cursor: pointer;
  &:hover{
    text-decoration: none;
    color: white;
  }
  &:before{
    content: "";
    background: url("../img/save.svg") center no-repeat;
    display: block;
    width: 24px;
    height: 24px;
    background-size: 24px;
    margin-right: 4px;
  }
}
.targets-grid__row{
  display: grid;
  grid-template-columns: minmax(300px, 2fr) minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr);
  grid-template-rows: auto;
  grid-auto-flow: row;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
  padding: 12px 24px;
  align-items: center;
  min-width: fit-content;
  width: 100%;
  textarea {
    box-sizing: border-box;
    resize: none;
    overflow: hidden;
    padding: 0;
  }

  &:nth-child(2n+1){
    background-color: #F9F9FA;
  }
  &:nth-child(2n){
    background-color:  white;
  }
  &:nth-child(1){
    background-color: white;
  }
  &._center{
    text-align: center;
  }
  &._right{
    text-align: right;
  }
}
.targets-grid{
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-gap: 0px;
  margin: 0 -24px;
  overflow-x: auto;
  @include scroll()
}
input.targets-table__title {
  width: 100%;
}
.targets-table__title{
  @include font($font, 400, 14px, 24px, $text-color-secondary);
  border: none;
  outline: none;
  cursor: default;
  &._center{
    text-align: center;
  }
  &._right{
    text-align: right;
    margin-right: 16px;
  }
  &._blue{
    color: #0870e0;
  }
}
.targets-grid_text{
  @include font($font, 600, 14px, 24px, $text-color);
  outline: none;
  border: none;
  background: transparent;
  cursor: default;
  margin: 0;
  max-width: 352px;
  &:hover,
  :active,
  :focus-visible{
    outline: none;
    border: none;
    background: transparent;
    cursor: default;
  }
}
.targets-field,
.targets-field-type{
  @include font($font, 400, 14px, 24px, $text-color);
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 16px;
  padding: 3px 15px;
  max-width: 160px;
  height: fit-content;
}
.targets-field{
  text-align: end;
  justify-self: end;
}
.targets-field-type{
  text-align: center;
  justify-self: center;
}
.justify-self-center{
  justify-self: center;
}
.align-self-center{
  align-self: center;
}
.justify-self-end{
  justify-self: end;
}
.target-help-btn{
  background: url("../img/question2.svg") center no-repeat;
  display: inline-block;
  background-size: 16px;
  width: 16px;
  height: 16px;
  border: none;
  outline: none;
  transform: translate(4px, 4px);
  transition: 0.3s;
  &:active,
  &:hover,
  &:focus-visible {
    background: url("../img/question-blue.svg") center no-repeat;
    border: none;
    outline: none;
    box-shadow: none;
  }
}
.event-block__name{
  @include font($font, 600, 14px, 24px, white);
  border-radius: 8px;
  padding: 12px 24px;
  text-align: center;
}
.modal-content{
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 24px 24px 16px 24px;
  border: none;
}
.modal-title{
  @include font($font, 600, 14px, 24px, $text-color);
  margin-bottom: 12px;
}
.modal-text{
  @include font($font, 400, 14px, 20px, $text-color);
  margin-bottom: 12px;
}

.modal-close-btn{
  @include font($font, 400, 12px, 24px, $text-color-secondary);
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  text-align: left;
  padding: 0;
  &:active,
  &:hover,
  &:focus-visible {
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
  }
}
.modal-backdrop{
  opacity: 0.15!important;
}
.add-contacts-btn{
  display: flex;
  @include font($font, 400, 16px, 24px, white);
  padding: 8px 12px;
  background: $main-light;
  border: none;
  border-radius: 8px;
  outline: none;
  box-shadow: none;
  margin-top: 16px;
  width: fit-content;
  &:before{
    content: '';
    background: url("../img/contact-add.svg") center no-repeat;
    width: 24px;
    height: 24px;
    background-size: 24px;
    margin-right: 4px;
  }
  &:active,
  &:hover,
  &:focus-visible {
    border: none;
    outline: none;
    box-shadow: none;
    color: white;
  }
}
.contact-delete-btn{
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 4;
  grid-row-end: 5;
  display: flex;
  @include font($font, 400, 14px, 24px, #ED0A34);
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
  &:before {
    content: "";
    display: block;
    background: url("../img/bin-2.svg") center no-repeat;
    width: 24px;
    height: 24px;
    background-size: 24px;
    margin-right: 4px;
    flex: none;
  }
  &:active,
  &:hover,
  &:focus-visible {
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
  }
}
.mt-32{
  margin-top: 32px;
}
.form-select{
  outline: none;
  box-shadow: none;
  @include font($font, 400, 14px, 24px, $text-color);
  width: 100%;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 8px;
  padding: 11px 32px 11px 15px;
  text-overflow: ellipsis;
  &:active,
  &:hover,
  &:focus-visible {
    border: 1px solid rgba(0, 16, 61, 0.12);
    outline: none;
    box-shadow: none;
  }
}
.project-creation-form{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  width: 100%;
  max-width: 600px;
}
.project-save-btn{
  display: flex;
  @include font($font, 400, 12px, 24px, white);
  border: none;
  border-radius: 8px;
  background: $main;
  outline: none;
  box-shadow: none;
  padding: 8px 24px;
  margin-top: 32px;
  width: fit-content;
  &:active,
  &:hover,
  &:focus-visible {
    border: none;
    outline: none;
    box-shadow: none;
  }
}
.container-600{
  max-width: 600px;
  width: 100%;
}

.category-wrap{
  margin-bottom: 16px;
}
.category-radio {
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    &:checked {
      + .radio-label {
        &:before {
          background: url("../img/radio-checked.svg") center no-repeat;
        }
      }
    }
    + .radio-label {
      display: flex;
      align-items: center;
      @include font($font, 400, 14px, 24px, $text-color);
      &:before {
        content: "";
        display: block;
        background: url("../img/radio-unchecked.svg") center no-repeat;
        width: 32px;
        height: 32px;
        background-size: 16px;
        border-radius: 8px;
        margin-right: 4px;
        flex: none;
      }
      &.color-1{
        &:before {
          background-color: #9DE4F2;
        }
      }
      &.color-2{
        &:before {
          background-color:  #FFDF7F;
        }
      }
      &.color-3{
        &:before {
          background-color: #ACE5BF;
        }
      }
    }

    &:focus {
      + .radio-label {
        &:before {
          outline: none;
        }
      }
    }
  }
}
.event-create__input{
  max-width: 288px;
  width: 100%;
}
.block-radio {
  input[type="radio"] {
    position: absolute;
    opacity: 0;

    + .block-label {
      display: flex;
      align-items: center;
      @include font($font, 400, 14px, 24px, $text-color);
      border: 1px solid rgba(0, 16, 61, 0.12);
      border-radius: 16px;
      padding: 3px 15px;
      margin: 0 8px 8px 0;
      width: fit-content;
      &.block-color-1{
        color: #B2937D;
      }
      &.block-color-2{
        color: #C798D9;
      }
      &.block-color-3{
        color: #56CCF2;
      }
      &.block-color-4{
        color: #F2C94C;
      }
      &.block-color-5{
        color: #6FCF97;
      }
    }
    &:checked {
      + .block-label {
        color: white;
        border: none;
        padding: 4px 16px;
        &.block-color-1{
          background-color: #B2937D;
        }
        &.block-color-2{
          background-color: #C798D9;
        }
        &.block-color-3{
          background-color: #56CCF2;
        }
        &.block-color-4{
          background-color: #F2C94C;
        }
        &.block-color-5{
          background-color: #6FCF97;
        }
      }
    }
    &:focus {
      + .block-label {
        outline: none;
      }
    }
  }
}
.radio-block__wrap{
  margin-top: 16px;
}
.event-creation__title{
  @include font($font, 600, 20px, 24px, $text-color);
  margin-bottom: 16px;
}
.event-creation__type{
  @include font($font, 400, 16px, 24px, $main);
  padding: 3px 15px;
  background-color: white;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 16px;
  margin: 0 8px 32px 0;
  &.active{
    @include font($font, 400, 16px, 24px, white);
    padding: 4px 16px;
    background-color: $main;
  }
}



.add-comment-title{
  @include font($font, 600, 20px, 32px, $main);
  margin-bottom: 12px;
}
.add-comment-modal{
  padding: 24px 32px 32px 32px;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
}
.add-comment-event{
  @include font($font, 600, 20px, 32px, $text-color);
  margin-bottom: 20px;
}
.modal-comment-header{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
}
.modal-dialog{
  max-width: 640px;
}
textarea {
  box-sizing: border-box;
  resize: none;
  overflow: hidden;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 8px;
  padding: 12px 24px;
  margin-bottom: 28px;
}
.textarea-label{
  @include font($font, 400, 14px, 24px, $text-color);
  margin-left: 12px;
}
.file-input{
  margin-bottom: 12px;
}
.comment__file-upload{
  @include font($font, 400, 14px, 24px, $text-color);
  background: #ECEDF0;
  border-radius: 16px;
  padding: 4px 24px;
  margin-right: 24px;
  cursor: pointer;
  white-space: nowrap;
  height: fit-content;
}
.file-upload__subtitle{
  @include font($font, 400, 12px, 16px, #919399);
  margin-bottom: 28px;
}
.uploaded{
  div{
    display: flex;
    justify-content: space-between;
    max-width: 392px;

    p{
      @include font($font, 400, 14px, 24px, $text-color);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
    }
  }
}
.delete-file{
  background: url("../img/delete-bin.svg") center no-repeat;
  display: block;
  width: 24px;
  height: 24px;
  background-size: 24px;
  margin-left: 8px;
  cursor: pointer;
  flex: none;
}
.comment-close-btn{
  @include font($font, 400, 14px, 24px, #919399);
  align-self: center;
  background: transparent;
  outline: none;
  border: none;
  background: url("../img/close.svg") center no-repeat;
  width: 24px;
  height: 24px;
  background-size: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
}

.comment-save-btn__wrap{
  background-color: #05458A;
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  cursor: pointer;
  width: 100%;
  max-width: 184px;
  justify-content: center;
  &:before{
    content: '';
    background: url("../img/clip.svg") center no-repeat;
    display: block;
    width: 24px;
    height: 24px;
    background-size: 24px;
    margin-right: 4px;
  }
}
.comment-save-btn{
  background-color: transparent;
  @include font($font, 400, 14px, 24px, white);
  outline: none;
  border: none;

}
.comment-finish-btn{
  @include font($font, 400, 14px, 24px, white);
  width: 100%;
  max-width: 184px;
  padding: 8px 4px 8px 4px;
  display: flex;
  justify-content: center;
  background: #FF9E00;
  border-radius: 8px;
  outline: none;
  border: none;
  &:before{
    content: '';
    background: url("../img/check.svg") center no-repeat;
    display: block;
    width: 24px;
    height: 24px;
    background-size: 24px;
    margin-right: 4px;
  }
  &._take-to-work{
    @include font($font, 400, 14px, 24px, white);
    background: #0DC268;
    &:before{
      content: '';
      background: url("../img/pencil-ruler-white.svg") center no-repeat;
      display: block;
      width: 24px;
      height: 24px;
      background-size: 24px;
      margin-right: 4px;
    }
  }
  &._accept{
    @include font($font, 400, 14px, 24px, white);
    background: #0DC268;
    &:before{
      content: '';
      background: url("../img/check.svg") center no-repeat;
      display: block;
      width: 24px;
      height: 24px;
      background-size: 24px;
      margin-right: 4px;
    }
  }
  &._decline{
    @include font($font, 400, 14px, 24px, white);
    background: #ED0A34;
    &:before{
      content: '';
      background: url("../img/event-back.svg") center no-repeat;
      display: block;
      width: 24px;
      height: 24px;
      background-size: 24px;
      margin-right: 4px;
    }
  }
}

.disabled-btn{
  background: #ecedf0!important;
}
._not-editable{
  border: none;
  background: transparent;
}
.multiple-files__label{
  background: url("../img/clip-gray.svg") center no-repeat;
  display: block;
  width: 24px;
  height: 24px;
  background-size: 24px;
  cursor: pointer;
  flex: none;
}

.file-input__name{
  @include font($font, 400, 14px, 20px, $main);
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  &:before{
    content: '';
    background: url("../img/file.svg") center no-repeat;
    display: block;
    width: 20px;
    height: 20px;
    background-size: 20px;
    margin-right: 4px;
    flex: none;
  }
}
.file-input__delete{
  background: url("../img/delete-bin.svg") center no-repeat;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 20px;
  margin-left: 8px;
  cursor: pointer;
  flex: none;
}
.add-file-input{
  display: none;
}
.file-row-item{
  display: flex;
  margin-bottom: 8px;
  &:first-child{
    margin-top: 12px;
  }
  &:last-child{
    margin-bottom: 12px;
  }
}
.text-in-approval{
  @include font($font, 400, 14px, 24px, #919399);
}
.loader {
  margin: 32px 0 0 24px;
  display: flex;
  align-self: center;
  font-size: 4px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;

}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #65abf8, 1.8em -1.8em 0 0em rgba(101,171,248, 0.2), 2.5em 0em 0 0em rgba(101,171,248, 0.2), 1.75em 1.75em 0 0em rgba(101,171,248, 0.2), 0em 2.5em 0 0em rgba(101,171,248, 0.2), -1.8em 1.8em 0 0em rgba(101,171,248, 0.2), -2.6em 0em 0 0em rgba(101,171,248, 0.5), -1.8em -1.8em 0 0em rgba(101,171,248, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.7), 1.8em -1.8em 0 0em #65abf8, 2.5em 0em 0 0em rgba(101,171,248, 0.2), 1.75em 1.75em 0 0em rgba(101,171,248, 0.2), 0em 2.5em 0 0em rgba(101,171,248, 0.2), -1.8em 1.8em 0 0em rgba(101,171,248, 0.2), -2.6em 0em 0 0em rgba(101,171,248, 0.2), -1.8em -1.8em 0 0em rgba(101,171,248, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.5), 1.8em -1.8em 0 0em rgba(101,171,248, 0.7), 2.5em 0em 0 0em #65abf8, 1.75em 1.75em 0 0em rgba(101,171,248, 0.2), 0em 2.5em 0 0em rgba(101,171,248, 0.2), -1.8em 1.8em 0 0em rgba(101,171,248, 0.2), -2.6em 0em 0 0em rgba(101,171,248, 0.2), -1.8em -1.8em 0 0em rgba(101,171,248, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.2), 1.8em -1.8em 0 0em rgba(101,171,248, 0.5), 2.5em 0em 0 0em rgba(101,171,248, 0.7), 1.75em 1.75em 0 0em #65abf8, 0em 2.5em 0 0em rgba(101,171,248, 0.2), -1.8em 1.8em 0 0em rgba(101,171,248, 0.2), -2.6em 0em 0 0em rgba(101,171,248, 0.2), -1.8em -1.8em 0 0em rgba(101,171,248, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.2), 1.8em -1.8em 0 0em rgba(101,171,248, 0.2), 2.5em 0em 0 0em rgba(101,171,248, 0.5), 1.75em 1.75em 0 0em rgba(101,171,248, 0.7), 0em 2.5em 0 0em #65abf8, -1.8em 1.8em 0 0em rgba(101,171,248, 0.2), -2.6em 0em 0 0em rgba(101,171,248, 0.2), -1.8em -1.8em 0 0em rgba(101,171,248, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.2), 1.8em -1.8em 0 0em rgba(101,171,248, 0.2), 2.5em 0em 0 0em rgba(101,171,248, 0.2), 1.75em 1.75em 0 0em rgba(101,171,248, 0.5), 0em 2.5em 0 0em rgba(101,171,248, 0.7), -1.8em 1.8em 0 0em #65abf8, -2.6em 0em 0 0em rgba(101,171,248, 0.2), -1.8em -1.8em 0 0em rgba(101,171,248, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.2), 1.8em -1.8em 0 0em rgba(101,171,248, 0.2), 2.5em 0em 0 0em rgba(101,171,248, 0.2), 1.75em 1.75em 0 0em rgba(101,171,248, 0.2), 0em 2.5em 0 0em rgba(101,171,248, 0.5), -1.8em 1.8em 0 0em rgba(101,171,248, 0.7), -2.6em 0em 0 0em #65abf8, -1.8em -1.8em 0 0em rgba(101,171,248, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.2), 1.8em -1.8em 0 0em rgba(101,171,248, 0.2), 2.5em 0em 0 0em rgba(101,171,248, 0.2), 1.75em 1.75em 0 0em rgba(101,171,248, 0.2), 0em 2.5em 0 0em rgba(101,171,248, 0.2), -1.8em 1.8em 0 0em rgba(101,171,248, 0.5), -2.6em 0em 0 0em rgba(101,171,248, 0.7), -1.8em -1.8em 0 0em #65abf8;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #65abf8, 1.8em -1.8em 0 0em rgba(101,171,248, 0.2), 2.5em 0em 0 0em rgba(101,171,248, 0.2), 1.75em 1.75em 0 0em rgba(101,171,248, 0.2), 0em 2.5em 0 0em rgba(101,171,248, 0.2), -1.8em 1.8em 0 0em rgba(101,171,248, 0.2), -2.6em 0em 0 0em rgba(101,171,248, 0.5), -1.8em -1.8em 0 0em rgba(101,171,248, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.7), 1.8em -1.8em 0 0em #65abf8, 2.5em 0em 0 0em rgba(101,171,248, 0.2), 1.75em 1.75em 0 0em rgba(101,171,248, 0.2), 0em 2.5em 0 0em rgba(101,171,248, 0.2), -1.8em 1.8em 0 0em rgba(101,171,248, 0.2), -2.6em 0em 0 0em rgba(101,171,248, 0.2), -1.8em -1.8em 0 0em rgba(101,171,248, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.5), 1.8em -1.8em 0 0em rgba(101,171,248, 0.7), 2.5em 0em 0 0em #65abf8, 1.75em 1.75em 0 0em rgba(101,171,248, 0.2), 0em 2.5em 0 0em rgba(101,171,248, 0.2), -1.8em 1.8em 0 0em rgba(101,171,248, 0.2), -2.6em 0em 0 0em rgba(101,171,248, 0.2), -1.8em -1.8em 0 0em rgba(101,171,248, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.2), 1.8em -1.8em 0 0em rgba(101,171,248, 0.5), 2.5em 0em 0 0em rgba(101,171,248, 0.7), 1.75em 1.75em 0 0em #65abf8, 0em 2.5em 0 0em rgba(101,171,248, 0.2), -1.8em 1.8em 0 0em rgba(101,171,248, 0.2), -2.6em 0em 0 0em rgba(101,171,248, 0.2), -1.8em -1.8em 0 0em rgba(101,171,248, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.2), 1.8em -1.8em 0 0em rgba(101,171,248, 0.2), 2.5em 0em 0 0em rgba(101,171,248, 0.5), 1.75em 1.75em 0 0em rgba(101,171,248, 0.7), 0em 2.5em 0 0em #65abf8, -1.8em 1.8em 0 0em rgba(101,171,248, 0.2), -2.6em 0em 0 0em rgba(101,171,248, 0.2), -1.8em -1.8em 0 0em rgba(101,171,248, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.2), 1.8em -1.8em 0 0em rgba(101,171,248, 0.2), 2.5em 0em 0 0em rgba(101,171,248, 0.2), 1.75em 1.75em 0 0em rgba(101,171,248, 0.5), 0em 2.5em 0 0em rgba(101,171,248, 0.7), -1.8em 1.8em 0 0em #65abf8, -2.6em 0em 0 0em rgba(101,171,248, 0.2), -1.8em -1.8em 0 0em rgba(101,171,248, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.2), 1.8em -1.8em 0 0em rgba(101,171,248, 0.2), 2.5em 0em 0 0em rgba(101,171,248, 0.2), 1.75em 1.75em 0 0em rgba(101,171,248, 0.2), 0em 2.5em 0 0em rgba(101,171,248, 0.5), -1.8em 1.8em 0 0em rgba(101,171,248, 0.7), -2.6em 0em 0 0em #65abf8, -1.8em -1.8em 0 0em rgba(101,171,248, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.2), 1.8em -1.8em 0 0em rgba(101,171,248, 0.2), 2.5em 0em 0 0em rgba(101,171,248, 0.2), 1.75em 1.75em 0 0em rgba(101,171,248, 0.2), 0em 2.5em 0 0em rgba(101,171,248, 0.2), -1.8em 1.8em 0 0em rgba(101,171,248, 0.5), -2.6em 0em 0 0em rgba(101,171,248, 0.7), -1.8em -1.8em 0 0em #65abf8;
  }
}




.delete-checkbox{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  input{
    display: none;
  }
  label{
    display: block;
    @include font($font, 400, 10px, 12px, #919399);
    display: flex;
    align-items: center;
    flex-direction: column;
    &:before{
      content: '';
      border: 2px solid #BFC1C7;
      border-radius: 4px;
      display: block;
      width: 16px;
      height: 16px;
      flex: none;
    }
  }
  input:checked + label{
    &:before{
      content: '';
      background: url("../img/checkmark.svg") #05458A center no-repeat;
      border-radius: 4px;
      display: block;
      width: 16px;
      height: 16px;
      flex: none;
      border: none;
    }
  }
}
.last-change{
  @include font($font, 400, 14px, 24px, #919399);
  margin-top: 8px;
  text-align: end;
}
