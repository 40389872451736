@import "vars";
.event-step {
  position: relative;
}
.tooltip {
  display: flex;
  align-items: center;
  position: absolute;
  top: 12px;
  left: calc(100% + 8px);
  width: max-content;
  background-color: $text-color;
  @include fnt(14, 24, #fff);
  padding: 0 8px;
  border-radius: 4px;
  opacity: 1;
  &::before {
    content: '';
    display: block;
    @include size(2px, 2px);
    position: absolute;
    top: 8px;
    left: -8px;
    border: 4px solid transparent;
    border-right: 4px solid $text-color;
  }
}
