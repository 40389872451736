@import "vars";

@import "header";
@import "sider";
@import "forms";
@import "login";
@import "notification";
@import "pagination";
@import "comment";
@import "tooltip";
@import "table";
@import "context-menu";
@import "metrics";
@import "project-indicators";



html {
  scroll-behavior: smooth;
  //font-size: calc((100 * 100vw / 1600 / 100) * 16);
}

@media screen and (max-width: 1023px) {
  html {
    //font-size: calc((100 * 100vw / 375 / 100) * 16);
  }
}


body {
  min-height: 100vh;
  display: flex;
  margin: 0;
  background-color: #F5F5F5;
}

.accessability {
  transform: translateY(-1px);
}
.main-content {
  flex: 1 0 auto;
}

h1, h2, h3, h4 {
  @include font($font, 600,
    clamp(24px, calc(32px + (32 - 24) * ((100vw - 320px) / (1920 - 360))), 32px),
    clamp(32px, calc(48px + (48 - 32) * ((100vw - 320px) / (1920 - 360))), 48px),
    $text-color);
  margin-bottom: 8px;
}

a,
a:hover {
  text-decoration: none;
  cursor: pointer;
}

p{
  margin: 0;
}
.main-wrap{
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  width: 100%;
}

.content{
  background: white;
  padding: 24px;
  margin: 8px;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 12px;
  /*  width: 100%;*/
  grid-column: 2/4;
}
.content__top-block{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 36px;
}

.content-title{
  @include font($font, 600, 24px, 40px, $main);
  display: flex;
  margin-right: 32px;
  &:before{
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    background-size: 24px;
    margin-right: 8px;
    border-radius: 50%;
  }
  &._organisations{
    &:before{
      background: url("../img/factory.svg") $main center no-repeat;
    }
  }
  &._projects{
    &:before{
      background: url("../img/folder.svg") $main center no-repeat;
    }
  }
  &._events{
    &:before{
      background: url("../img/board.svg") $main center no-repeat;
    }
  }
  &._metrics{
    &:before{
      background: url("../img/Graph.svg") $main center no-repeat;
    }
  }
}
.add-btn{
  @include font($font, 400, 16px, 24px, white);
  display: flex;
  width: fit-content;
  height: fit-content;
  padding: 8px 12px;
  background-color: $green;
  border-radius: 8px;
  text-align: center;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  border: none;
  &:hover{
    color: white;
  }
  &:before{
    background: url("../img/add.svg") center no-repeat;
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background-size: 24px;
    margin-right: 4px;
  }
  &._edit:before {
    background: url("../img/Edit-white.svg") center no-repeat;
  }
   &._edit-pen{
    background: $main;
    &:before {
      background: url("../img/edit-pen.svg") center no-repeat;
    }
  }
  &._outline {
    background-color: transparent;
    color: $green;
    font-weight: 600;
    border: 1px solid $green;
    &:before {
      background: url("../img/add-green.svg") center no-repeat;
    }
    &._edit:before {
      background: url("../img/Edit-green.svg") center no-repeat;
    }
  }
}

.search{
  display: flex;
  position: relative;
  height: fit-content;
}
.search-term{
  @include font($font, 400, 14px, 24px, $text-color-secondary);
  width: 360px;
  outline: none;
  padding: 7px 11px 7px 39px;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 8px 0 0 8px;;
  background: url("../img/loupe.svg") 11px no-repeat;
  background-size: 24px;
  &:focus{
    color: $text-color;
  }
}
.search-button{
  @include font($font, 400, 14px, 24px, white);
  background: $main;
  border: none;
  outline: none;
  border-radius: 0 8px 8px 0;
  width: fit-content;
  height: fit-content;
  padding: 8px 16px;
  margin-left: -4px;
}
.search-clear{
  position: absolute;
  right: 88px;
  top: 50%;
  transform: translate(0, -50%);
  background: url("../img/bin-2.svg") no-repeat;
  background-size: 24px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.notification-gray,
.notification-blue {
  @include font($font, 600, 14px, 24px, white);
  display: block;
  padding: 0px 12px;
  border-radius: 12px;
  width: fit-content;
  height: fit-content;
  align-self: center;
  min-width: 24px;
}
.notification-gray {
  background: #BFC1C7;
  background-blend-mode: multiply;
  opacity: 0;
  transition: opacity .2s;
}

.notification-white{
  @include font($font, 400, 14px, 16px, $main);
  background: white;
  padding: 4px 8px;
  border-radius: 12px;
  width: fit-content;
  height: fit-content;
  min-width: 24px;
  align-self: center;
  background-blend-mode: multiply;
}
.notification-blue{
  background: $main;
  margin-left: 4px;
  &:before{
    content: "+";
    @include font($font, 600, 14px, 24px, white);
  }
}

.organisations__list {
  .string {
    max-width: 960px;
    grid-template-columns: 32px minmax(140px,3fr) minmax(92px,1fr);
  }
}
.projects__list {
  .string {
    grid-template-columns:
    32px
    minmax(140px, 3fr)
    minmax(230px, 1fr)
    minmax(140px, 2fr)
    minmax(92px, 1fr)
    minmax(92px, 1fr);
    .stage {
      align-self: center;
      justify-self: center;
      @include fnt(14px, 24px);
      padding: 0 12px;
      border-radius: 12px;
      background-color: $gray-20;
    }
  }
}

.organisations__list,
.projects__list {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  margin: 0 -24px;
  overflow-x: auto;
  @include scroll();
  .string{
    display: grid;
    align-items: center;
    grid-template-rows: 100%;
    //max-width: 1000px;
    grid-column-gap: 16px;
    padding-right: 24px;
  }
  .table-row{
    padding: 12px 0;
  }
  .table-row:nth-child(odd){
    background-color: #F9F9FA;
    .stage {
      background-color: white;
    }
  }
  .table-row:nth-child(1){
    background-color: white;
  }
  .table__row-nbr{
    text-align: right;
    line-height: 26px;
  }
}
.organisations__list .string p:nth-child(3) {
  text-align: right;
}


.events__list {
  .string {
    grid-template-columns:
      12px
      minmax(320px, 5fr)
      44px
      minmax(116px, 1fr)
      minmax(150px, 1fr)
      minmax(92px, 1fr)
      minmax(92px, 1fr)
      32px
      24px;
  }
}
.project-detail {
  .string {
    grid-template-columns:
        12px
        minmax(320px, 5fr)
        44px
        minmax(116px, 1fr)
        minmax(92px, 1fr)
        minmax(92px, 1fr)
        minmax(92px, 1fr)
        minmax(92px, 1fr)
        32px
      24px;
  }
}

.project-detail,
.events__list{
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  margin: 0 -24px;
  overflow-x: auto;
  @include scroll();
  .accordion-item{
    background-color: transparent;
  }
  .string{
    display: grid;
    grid-template-rows: 100%;
    grid-column-gap: 16px;
    padding-right: 16px;
    .accordion-arrow{
      background: url("../img/arrow-down.svg") center no-repeat;
      background-size: 16px;
      width: 24px;
      height: 24px;
      display: block;
      position: sticky;
      background-color: #fff;
      border: 1px solid $border-color;
      border-radius: 12px;
      top: 0;
      right: 16px;
      transition: transform 0.3s, opacity .2s;
      transform-origin: center;
      transform: rotate(180deg);
      opacity: 0;
    }
    .collapsed.accordion-arrow{
      transform: rotate(0deg);
    }
    &.title-row{
      &:after{
        content: '';
        background: none;
      }
    }

    .table-object a{
      @include font($font, 600, 14px, 18px, $text-color);
    }
  }

  .table-row{
    padding: 12px 0;
    min-width: fit-content;
    width: 100%;
  }
  .table-row.accordion.blue{
    background-color:#C8F2FA;
  }
  .table-row.accordion.green{
    background-color:#C6F7CE;
  }
  .table-row.accordion.yellow{
    background-color:#FFDD99;
  }
  .table-row.accordion.red{
    background-color:#FAC8D0;
  }
}
.accordion-body._comment {
  border-bottom: 1px solid rgba(0, 16, 61, 0.12);
  margin-bottom: -12px;
}

.table-title,
.table__row-nbr{
  @include font($font, 400, 14px, 24px, $text-color-secondary);
}
.table-title {
  font-size: 12px;
  align-self: center;
}
.table__row-nbr{
  display: flex;
  justify-content: flex-end;
}

.table-object{
  @include font($font, 600, 14px, 24px, $text-color);
}
.event-list-all{
  .string{
    a{
      width: 100%;
    }
  }
}
.event-count{
  @include font($font, 400, 14px, 24px,  rgba(44, 45, 46, 0.4));
}

.table-value{
  display: block;
  @include font($font, 400, 14px, 24px, $text-color);
  &.title {
    font-weight: 600;
    line-height: 18px;
  }
}
.line{
  width: 100%;
  height: 1px;
  background: rgba(0, 16, 61, 0.12);
}
.accordion-item{
  border: none;
  outline: none;
}
.event-step{
  width: 100%;
  margin: -12px 0;
}
.event__abbr-block{
  @include font($font, 400, 14px, 24px, white);
  padding: 0 12px;
  border-radius: 12px;
  width: fit-content;
  height: fit-content;
}
.organisation-inf__text{
  @include font($font, 400, 16px, 24px, $text-color);
}
.organisation-inf__text-bold{
  @include font($font, 600, 16px, 24px, $text-color);
}
.organisation-inf__label{
  @include font($font, 400, 12px, 24px, rgba(145, 147, 153, 1));
}
.project-name{
  @include font($font, 600, 20px, 24px, $text-color);
  margin-bottom: 16px;
}
.targets-detail-grid__row{
  display: grid;
  grid-template-columns: minmax(300px, 2fr) minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) 24px;
  grid-template-rows: auto;
  grid-auto-flow: row;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
  padding: 12px 20px;
  align-items: center;
  min-width: fit-content;
  & .accordion-arrow{
    background: url("../img/arrow-down.svg") right no-repeat;
    background-size: 16px;
    width: 24px;
    height: 24px;
    display: block;
    transition: 0.3s;
    transform: rotate(180deg) translateX(-8px);
  }
  & .collapsed.accordion-arrow{
    transform: rotate(0deg);
  }
  .organisations-detail__file-wrap{
    grid-column: 1/9;
    margin: 12px 0 4px 0;
  }
  textarea {
    box-sizing: border-box;
    resize: none;
    overflow: hidden;
    padding: 0;
  }
  &:nth-child(2n+1){
    background-color: #F9F9FA;
  }
  &:nth-child(2n){
    background-color:  white;
  }
  &:nth-child(1){
    background-color: white;
  }
}
._center{
  text-align: center;
  justify-self: center;
}
._right{
  text-align: right;
  justify-self: right;
}

.detail-file-accordion,
.file-input__name{
  width: 100%;
  max-width: calc(100% - 32px);
}
.file-input__name{
  margin-bottom: 8px;
  span{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }
}
input[name="targets_period_year_1"] {
  color: #0870e0;
}

