@import "vars";

.pagination{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 48px;
  margin-bottom: 24px;
  .step-links {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
    & > * {
      align-items: center;
    }
  }
}
.prev-arrow__text{
  @include font($font, 400, 16px, 24px, $main);
  margin-left: 4px;
  pointer-events: none;
}
.next-arrow__text{
  @include font($font, 400, 16px, 24px, $main);
  margin-right: 4px;
  pointer-events: none;
}
.pagination__prev-arrow{
  width: 24px;
  height: 24px;
  stroke: $main;
  transition: 0.3s;
}
.pagination__next-arrow{
  width: 24px;
  height: 24px;
  stroke: $main;
  transform: rotate(180deg);
  transition: 0.3s;
}
.disabled{
  .pagination__prev-arrow,
  .pagination__next-arrow {
    opacity: 40%;
    stroke: #BFC1C7!important;
  }
}
.prev__arrow-border{
  padding: 8px;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 20px;
  display: flex;
  &:hover{
    .pagination__prev-arrow{
      transform: translate(-4px,0);
      transition: 0.3s;
    }
  }
}
.next__arrow-border{
  padding: 8px 8px 8px 20px;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 20px;
  display: flex;
  &:hover{
    .pagination__next-arrow{
      transform: rotate(180deg) translate(-4px,0);
      transition: 0.3s;
    }
  }
}
.arrow-border{
  padding: 8px;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 20px;
  display: flex;
}
.pagination-block{
  margin-top: 48px;
}
.paginate-item{
  @include font($font, 400, 14px, 42px, #919399);
}
.current_page{
  margin-left: 12px;
  cursor: default;
  &:hover{
    text-decoration: none;
    color: #919399;
  }
}
span.last_page{
  margin-right: 12px;
  cursor: default;
  &:hover{
    text-decoration: none;
    color: #919399;
  }
}

a.last_page{
  margin-right: 12px;
  &:hover{
    text-decoration: none;
    color: $main;
  }
}
.excuse{
  cursor: default;
  &:hover{
    text-decoration: none;
    color: #919399;
  }
}
