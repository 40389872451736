@import "vars";


//          MODAL AS LEFT/RIGHT SIDEBAR

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  max-width: 820px;
  height: 100%;
  width: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 0;
}

/*Left*/
.modal.left.fade .modal-dialog{
  left: -820px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.show .modal-dialog{
  left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: -820px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}



.project-indicators__modal{
  .modal-header{
    background: white;
    padding: 0;
    border: none;
    margin-bottom: 24px;
    .modal__back-btn{
      background: url("../img/back-arrow.svg") #ECEDF0 center no-repeat;
      display: block;
      width: 40px;
      height: 40px;
      background-size: 24px;
      border-radius: 50%;
      flex: none;
      padding: 0;
    }
    .project-indicator__title{
      @include font($font, 600, 24px, 40px, $main);
      display: flex;
      &:before{
        content: '';
        display: block;
        background: url("../img/edit-pen.svg") $main center no-repeat;
        width: 40px;
        height: 40px;
        background-size: 24px;
        flex: none;
        margin-right: 8px;
        border-radius: 50%;
        padding: 0;
      }
    }
  }
  .modal-content{
    padding: 24px;
    border-radius: 0;

  }
  .modal-footer{
    padding: 0;
    border: none;

  }
}
.project-indicators__save{
  @include font($font, 400, 16px, 24px, white);
  display: flex;
  position: absolute;
  bottom: 24px;
  right: 24px;
  border-radius: 8px;
  padding: 8px 12px;
  background: #0DC268;
  outline: none;
  border: none;
  width: fit-content;
  &:before{
    content: '';
    display: block;
    background: url("../img/save.svg") center no-repeat;
    width: 24px;
    height: 24px;
    background-size: 24px;
    flex: none;
    margin-right: 4px;

  }
}
.project-indicators__add{
  @include font($font, 400, 16px, 24px, white);
  position: absolute;
  bottom: 24px;
  left: 24px;
  display: flex;
  border-radius: 8px;
  padding: 8px 12px;
  background: $main;
  outline: none;
  border: none;
  width: fit-content;
}
.project-indicators__table{
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  margin: 0 -24px;
  overflow-x: auto;
}
.project-indicators__row{
  padding: 12px 0;
  min-width: fit-content;
  width: 100%;
  &:nth-child(2n+1){
    background-color:  white;
  }
  &:nth-child(2n){
    background-color: #F9F9FA;
  }
}
.project-indicators__string{
  display: grid;
  grid-template-rows: 100%;
  grid-column-gap: 16px;
  padding: 0 24px;
  //grid-template-columns: minmax(160px,2fr) minmax(86px,1fr) minmax(86px,1fr) minmax(86px,1fr) minmax(86px,1fr) minmax(86px,1fr);
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr 0 1fr;

  .indicators__text{
    input{
      @include font($font, 400, 14px, 24px, $text-color);
      width: 100%;
      border: none;
      outline: none;
      -webkit-user-modify: read-only;
      width: 100%;
      text-align: right;
      background: transparent;
    }
    &._name{
      input{
        background: transparent;
        width: 100%;
        border: none;
        outline: none;
        -webkit-user-modify: read-only;
        width: 100%;
        text-align: left;
      }
    }
  }
  .indicators__input{
    input{
      border: 1px solid rgba(0, 16, 61, 0.12);
      border-radius: 16px;
      outline: none;
      @include font($font, 400, 14px, 24px, $text-color);
      padding: 4px 12px;
      width: 100%;
      text-align: right;
    }
    &._name{
      input{
        text-align: left;
      }
    }
  }
}
.popup-form{
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}
