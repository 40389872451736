@import 'vars';

.context-menu{
  &__wrap{
    display: flex;
    position: absolute;
    flex-direction: column;
    background-color: white;
    padding: 4px 0;
    border-radius: 4px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  }
  &__edit,
  &__delete
  {
    @include fnt(12, 24);
    display: flex;
    padding: 8px 16px;
    margin: 0;
    transition: background-color 0.3s;
    &:hover{
      background-color: $gray-20;
      text-decoration: none;
    }
  }
  &__edit{
    color: $text-color;
    &:hover{
      color: $text-color;
    }
    &:before{
      content: "";
      background: url("../img/Edit.svg") center no-repeat;
      display: block;
      width: 24px;
      height: 24px;
      background-size: 24px;
      margin-right: 4px;
    }
  }
  &__delete{
    color: #ED0A34;
    &:hover{
      color: #ED0A34;
    }
    &:before{
      content: "";
      background: url("../img/bin-2.svg") center no-repeat;
      display: block;
      width: 24px;
      height: 24px;
      background-size: 24px;
      margin-right: 4px;
    }
  }
}
.selected{
  box-shadow: inset 0px 0px 0px 1px $main;
}
