@import "vars";



.table-scroll {
  width: calc(100% + 48px);
  overflow-x: auto;
  margin: 0 -24px;
}
table {
  display: grid;
  border-collapse: collapse;
}

thead,
tbody,
tr {
  display: contents;
}
.table-title{

}
th,
td {
  width: 100%;
  padding: 12px;
  @include fnt(14, 24);
  &:first-child{
    padding-left: 24px;
  }
  &:last-child {
    padding-rightt: 24px;
  }
  &._gray {
    color: $text-color-secondary
  }
  &._center {
    text-align: center;
  }
  &._left {
    text-align: left;
  }
  &._right {
    text-align: right;
  }
  &._no-wrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

th {
  position: sticky;
  top: 0;
  &._border {
    position: relative;
    border-bottom: 1px solid $border-color;
    overflow: visible;
    &:first-child {
      &:before {
        content: '';
        position: absolute;
        display: block;
        background-color: #fff;
        @include size(2px, 24px);
        bottom: -1px;
        left: 0;
      }
    }
    &:last-child {
      &:after {
        content: '';
        position: absolute;
        display: block;
        background-color: #fff;
        @include size(2px, 24px);
        bottom: -1px;
        right: 0;
      }
    }
  }
}

th:last-child {
}

td {

}

tr:nth-child(even) td {
  background: $gray-20;
}


a.table-value,
p.table-value {
  width: 100%;
}

.table-border-bottom {
  position: relative;
  border-bottom: 1px solid $border-color;
  overflow: visible;
  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    background-color: #fff;
    @include size(2px, 24px);
    bottom: -1px;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
}
