@import "vars";


.login-background{
  background: url("../img/rck-background.svg") $main;
  background-position: left top;
  background-repeat: repeat;
  background-size: 288px 288px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.login-container-center{
  max-width:560px;
  padding: 0 64px;
  width: 100%;
  margin: 0 auto;
  align-self: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.login-block{
  background: #FFFFFF;
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.35);
  border-radius: 16px;
  padding: 32px 64px 40px 64px;
  display: flex;
  flex-direction: column;
}
.rck-login-img{
  width: 100%;
  max-width: 193px;
  flex: none;
  margin-bottom: 4px;
  align-self: center;
}
.login-subtitle{
  @include font($font, 400, 16px, 24px, $text-color);
  margin-bottom: 16px;
  text-align: center;
  align-self: center;
}
.login-enter{
  @include font($font, 600, 16px, 24px, white);
  text-align: center;
  background: #0B3564;
  border-radius: 8px;
  width: 100%;
  padding: 12px 0;
  margin-top: 40px;
}
.indicator-logo{
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
}

