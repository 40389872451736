@import "vars";

.metrics-title{
  @include font($font, 600, 20px, 24px, white);
  margin-bottom: 8px;
}
.metric-name{
  @include font($font, 400, 14px, 24px, white);
  margin-right: 4px;
  &._black{
    color: $text-color;
  }
}
.metric-count{
  @include font($font, 400, 16px, 24px, $text-color);
  background: #FFFFFF;
  border-radius: 12px;
  padding: 0 8px;
  flex: none;
  height: fit-content;
}
.metric-item{
  display: flex;
  margin-right: 24px;
}
.metrics-total{
  display: flex;
  flex-direction: column;
  background: url("../img/rck-metrics.svg") $main top right no-repeat;
  padding: 12px 24px 0 24px;
  margin-bottom: 24px;
}
.mb-12{
  margin-bottom: 12px;
}
.metrics__project-status{
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.metrics__name-wrap{
  display: flex;
  margin-bottom: 8px;
}
.metrics__project-name{
  @include font($font, 600, 16px, 24px, $text-color);
}
.metrics__organisation-name{
  @include font($font, 600, 20px, 24px, $main);
  margin-bottom: 8px;
}
.statistics-block{
  display: flex;
}
.statistics-item{
  display: flex;
  background: white;
  border-radius: 8px;
  padding: 8px 16px;
  margin-right: 8px;
}
.metric-number{
  @include font($font, 400, 16px, 20px, $text-color);
  text-align: center;
  &._green{
    color: #0DC268;
  }
  &._red{
    color: #ED0A34;
  }
}
.metric-subtitle{
  @include font($font, 400, 12px, 16px, $text-color-secondary);
  text-align: center;
}
.separator{
  display: block;
  width: 1px;
  height: 100%;
  background: rgba(0, 16, 61, 0.12);
  margin: 0 12px;
}
.metrics-accordion{
  background: #F3F3F5;
  padding: 8px 24px;
  margin-bottom: 16px;
}
.metrics-accordion__arrow{
  background: url("../img/accordion-arrow.svg") center no-repeat;
  width: 24px;
  height: 24px;
  background-size: 24px;
  flex: none;
  transform: rotate(0deg);
  transition: 0.3s;
  &.collapsed{
    transform: rotate(180deg);
    transition: 0.3s;
  }
}
.metrics-select{
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 8px;
  outline: none;
  padding: 8px 4px 8px 16px;
  max-width: 80px;
}
.metrics-popup{
  @include font($font, 400, 16px, 24px, white);
  background: #05458A;
  border-radius: 8px;
  padding: 14px 16px 14px 12px;
  display: flex;
  &:hover{
    color: white;
  }
  &:before{
    content: '';
    display: block;
    background: url("../img/edit-pen.svg") center no-repeat;
    width: 24px;
    height: 24px;
    background-size: 24px;
    flex: none;
    margin-right: 4px;
  }
}
.mb-20{
  margin-bottom: 20px;
}
.metrics-separator{
  margin: 0 4px;
align-self: center;
  width: 1px;
  height: 16px;
  display: block;
  background: rgba(0, 16, 61, 0.12);
}
