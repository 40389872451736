$main: #05458A;
$main-dark: #0B3564;
$main-light: #0870E0;
$text-color: #2C2D2E;
$text-color-secondary: #919399;
$gray-20: #F9F9FA;
$gray-40:  #F5F5F5;
$yellow: #FDE953;
$error: #ff0019;
$green: #0DC268;
$font: 'Golos', sans-srif;
$border-color: rgba(0, 16, 61, 0.12);
$border-color-active: rgba(0, 16, 61, 0.24);
$shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
$shadow-active: 0 14px 24px rgba(0, 0, 0, 0.15);
$grey:   #f2f2f2;
$darker-grey: #c8c8c8;


@mixin gradient-color () {
  background: linear-gradient(135deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}


@mixin size ($height, $width) {
  height: $height;
  width: $width;
}

@mixin font ($font, $weight, $size, $height, $color) {
  font-family: $font;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
  color: $color;
}
@mixin fnt ($size: 16, $height: 24, $color: $text-color, $weight: 400) {
  font-family: $font;
  font-style: normal;
  font-weight: $weight;
  font-size: #{$size}px;
  line-height: #{$height}px;
  color: $color;
}


@mixin btn($bg-color: $main, $color: #fff, $outline: false) {
  @include font($font, 600, 16px, 24px, $color);
  display: inline-block;
  padding: 12px 24px;
  background-color: $bg-color;
  border-radius: 8px;
  text-align: center;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  @if $outline {
    border: 2px solid $color;
    padding: 8px 20px;
  } @else {
    border: none;
  }
  &:hover {
    @if $outline {
      background-color: $color;
      color: $bg-color;
    } @else {
      background-color: $bg-color - #111;
    }
  }
}

@mixin scale($selector, $top-min, $top-max, $left-min: 0, $left-max: 0) {
  @if $left-min != 0 or $left-max != 0 {
    #{$selector}: clamp(#{$top-min}px, calc(#{$top-min}px + (#{$top-max} - #{$top-min}) * ((100vw - 320px) / (1920 - 320))), #{$top-max}px) clamp(#{$left-min}px, calc(#{$left-min}px + (#{$left-max} - #{$left-min}) * ((100vw - 320px) / (1920 - 320))), #{$left-max}px);
  } @else {
    #{$selector}: clamp(#{$top-min}px, calc(#{$top-min}px + (#{$top-max} - #{$top-min}) * ((100vw - 320px) / (1920 - 320))), #{$top-max}px);
  }
}


@mixin afont($fmin, $fmax, $lmin, $lmax, $weight: 400, $color: $text-color) {
  font-family: $font;
  font-style: normal;
  font-weight: $weight;
  font-size: clamp(#{$fmin}px, #{$fmin}px + (#{$fmax} - #{$fmin}) * ((100vw - 360px) / (1680 - 340)),  #{$fmax}px);
  line-height: clamp(#{$lmin}px, #{$lmin}px + (#{$lmax} - #{$lmin}) * ((100vw - 360px) / (1680 - 340)),  #{$lmax}px);
  color: $color;
}

@mixin scroll() {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $main;
    border: 2px solid #555555;
  }
}
