@font-face {
  font-family: 'Golos';
  src:
    url('fonts/Golos_Text/Golos-Text_Regular.woff2') format('woff2'),
    url('fonts/Golos_Text/Golos-Text_Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Golos';
  src:
    url('fonts/Golos_Text/Golos-Text_Medium.woff2') format('woff2'),
    url('fonts/Golos_Text/Golos-Text_Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Golos';
  src:
    url('fonts/Golos_Text/Golos-Text_DemiBold.woff2') format('woff2'),
    url('fonts/Golos_Text/Golos-Text_DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Golos';
  src:
    url('fonts/Golos_Text/Golos-Text_Bold.woff2') format('woff2'),
    url('fonts/Golos_Text/Golos-Text_Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
