@import "vars";

.sidebar{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  background: $main;
  padding: 20px 16px 0 16px;
  width: 244px;
  height: 100vh;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
  }
  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include size(24px, 24px);
    &::after {
      content: '';
      display: block;
      border: 8px solid transparent;
      border-right: 8px solid #fff;
      transform: translateX(-8px);
    }
  }
  &.closed {
    width: 64px;
    .to-hidden {
      display: none;
    }
    .notification-white {
      font-size: 10px;
      line-height: 10px;
      padding: 1px 4px;
      min-width: 15px;
      margin: -15px 0 0 0;
      transform: translateX(-19px);
      flex: none;
    }
    .sidebar__arrow {
      &::after {
        transform: translateX(8px) rotate(180deg);
      }
    }
    .sidebar-line {
      width: calc(100% - 16px);
      margin: 8px 8px;
    }
  }
}
.rck-logo{
  width: 100%;
  max-width: 116px;
  height: auto;
  flex: none;
}
.sidebar-menu{
  display: flex;
  flex-direction: column;
  margin: 0 -16px;
}
.sidebar-line{
  width: calc(100% - 32px);
  height: 1px;
  background: rgba(255, 255, 255, 0.5);
  margin: 8px 16px;
}

.sidebar-menu__item{
  @include font($font, 400, 16px, 24px, white);
  display: flex;
  pointer-events: none;
  &:hover{
    color: white;
  }
  &.notifications{
    &:before{
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      background: url("../img/notification.svg") center no-repeat;
      background-size: 24px;
      margin-right: 8px;
    }
  }
  &._organisations{
    &:before{
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      background: url("../img/factory.svg") center no-repeat;
      background-size: 24px;
      margin-right: 8px;
    }
  }
  &._projects{
    &:before{
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      background: url("../img/folder.svg") center no-repeat;
      background-size: 24px;
      margin-right: 8px;
    }
  }
  &._events{
    &:before{
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      background: url("../img/board.svg") center no-repeat;
      background-size: 24px;
      margin-right: 8px;
    }
  }
  &._metrics{
    &:before{
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      background: url("../img/Graph.svg") center no-repeat;
      background-size: 24px;
      margin-right: 8px;
    }
  }
}
.menu-item__wrap{
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  transition: 0.3s;
  &:hover{
    background-color: #0B3564;
  }
  &.active{
    background-color: $main-light;
    &:hover{
      background-color: $main-light;
    }
  }
}


//    ---------- Footer ---------


.footer-content{
  background-color: $main-dark;
  padding: 12px 16px;
  margin: 0 -16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logout-btn{
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background: url("../img/logout.svg") center no-repeat;
  background-size: 24px;
}
.person-information{
  display: flex;
  flex-direction: column;
  .person-name{
    @include font($font, 400, 16px, 24px, white);
  }
  .person-position{
    @include font($font, 400, 12px, 16px, white);
    opacity: 0.5;
  }
}
