@import "vars";

.comment-table {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: auto auto 1fr;
  grid-gap: 8px 12px;
  background-color: transparent;
  padding: 12px 24px;
  &__date {
    @include fnt(14, 24, $text-color-secondary);
  }
  &__name {
    @include fnt(14, 24);
    b {
      font-weight: 600;
    }
  }
  &__comment {
    display: flex;
    @include fnt(14, 24, $main);
    &:before {
      content: '';
      display: block;
      @include size(24px, 24px);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin: 0 4px 0 0;
    }
    &._link {
      font-weight: 600;
      &:before {
        background-image: url("../img/comments/file.svg");
      }
    }
    &._text {
      &:before {
        background-image: url("../img/comments/message.svg");
      }
    }
    &._fact {
      &:before {
        background-image: url("../img/comments/clock.svg");
      }
    }
  }
}
.accordion-body._comment {
  padding: 12px 0;
}
