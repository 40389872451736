@import "vars";


#menu-app {
  min-height: 70px;
}

.header {
  position: relative;
  padding-top: 28px;
  padding-bottom: 12px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  z-index: 5;
  background-color: #fff;
}

.header-wrap{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.header-logo{
  width: 104px;
  height: auto;
  flex: none;
  margin-right: 48px;
}
.header-info{
  border-bottom: 1px solid rgba(0, 16, 61, 0.12);
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 12px;
}
.header-menu{
  display: flex;
  align-items: center;
}
.header-phone{
  @include font($font, 400, 16px, 24px, $text-color);
  text-decoration: none;
  &:hover{
    text-decoration: none;
    color: $text-color;
  }
}
.first-menu {
  display: flex;
  align-items: center;
}
.phone-img{
  width: 16px;
  height: 24px;
  flex: none;
  margin-right: 8px;
}
.login-button {
  display: inline-block;
  padding: 12px 24px;
  font-family: $font;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #FFF;
  background-color:$main;
  border-radius: 4px;
  text-align: center;
  border: none;
  margin-left: 24px;
  &:hover{
    text-decoration: none;
    color: #FFF;
  }
}
.social_button{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 16px;
  background: $main;
  object-position: center;
  object-fit: scale-down;
  &._vk{
    content: url("../img/socials/vk.svg");
    background:  #2787F5;

  }
  &._odnoklassniki{
    content: url("../img/socials/ok.svg");
    background: #EE8208;

  }
  &._telegram{
    content: url("../img/socials/telegram.svg");
    background: linear-gradient(203.21deg, #37AEE2 21.68%, #1E96C8 69.99%);;
  }
  &._facebook{
    content: 'f';
  }
  &._youtube{
    content: 'YT';
  }
  &._instagram{
    content: 'i';
  }
  &._twitter{
    content: 't';
  }
  &._rutube{
    content: url("../img/socials/rutube.svg");
    background: #14191F;
  }
}

/* START Mobile header */

.header-mobile {
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-auto-flow: column;
  position: sticky;
  width: 100%;
  padding: 16px;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: $shadow;
  z-index: 90;
  .nav-mobile {
    display: none; //grid
    grid-template-rows: max-content auto min-content min-content;
    position: absolute;
    top: 100%;
    background-color: #fff;
    width: 100%;
    height: calc(100vh - 100%);
    padding: 0 16px 60px;
    overflow-y: auto;
  }
  .first-menu {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    margin-right: -16px;
    margin-left: -16px;
    background-color: $gray-20;
  }
  .second-menu {
    grid-auto-flow: row;
    padding: 16px 0;
    grid-gap: 8px;
  }
  .second-menu__item {
    align-items: flex-start;
    .second-menu__icon {
      display: none;
    }
  }
}

.burger {
  @include size(42px, 56px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
}

.burger span {
  position: relative;
}

.burger span::before,
.burger span::after {
  content: '';
  position: absolute;
  top: 0;
  transform-origin: center;
}
.burger span::before,
.burger span::after,
.burger span {
  display: block;
  @include size(3px, 32px);
  background-color: #00b5cc;
  transition: 0.2s ease-out;
}

.burger span::before {
  transform: translateY(-8px);
  background-color: #0cc;
}
.burger span::after {
  background-color: $main;
  transform: translateY(8px);
}
.header__menu_active {
  overflow: hidden !important;
  .burger span {
    background-color: transparent;
  }
  .burger span::before {
    background-color: $main;
    transform: rotate(45deg);
  }
  .burger span::after {
    transform: rotate(-45deg);
  }
  .nav-mobile {
    display: grid;
  }
}


/* END Mobile header */
