@import "vars";


.table_notifications {
  grid-template-columns: auto minmax(450px, 1fr);
}

.content-title._notification:before {
  background: url(../img/notification.svg) #05458a center no-repeat;
}
.projects__list {
  &._notifications {
    .table-row .string {
      grid-template-columns: 1fr 6fr;
      .table__row-nbr {
        padding-left: 24px;
      }
    }
  }
}
.notify-circle-True {
  position: relative;
  &::before {
    content: 'Новое';
    display: block;
    @include fnt(10, 12, $color: #fff, $weight: 600);
    padding: 0 6px 2px;
    position: absolute;
    top: -10px;
    left: 0;
    flex: none;
    background-color: $green;
    border-radius: 8px;
  }
}
.notify .notify-circle-True::before {
  top: -14px;
}
.notification-item p{
  display: flex;
  flex-wrap: wrap;
}
.notification-item p * {
    margin: 0 4px;
}
.string {
  a {
    display: block;
    min-width: 24px;
    transition: color 0.2s ease-out;
    cursor: pointer;
    &:hover {
      color: $main-light !important;
    }
  }
}
.filter {
  &__container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: -12px 0 0;
  }
  &__button {
    display: none;
  }
  &__label {
    display: block;
    @include fnt($color: $main);
    padding: 3px 15px;
    border: 1px solid $border-color;
    border-radius: 16px;
    margin-right: 8px;
    cursor: pointer;
    transition: border-color 0.2s ease-out;
    margin-bottom: 16px;
    &._active {
      border-color: $main;
      background-color: $main;
      color: #fff;
      ._status-img{
        stroke: white;
      }
    }
    &:hover {
      border-color: $main;
    }
    ._status-img{
      stroke: $main;
    }
  }
}
.icon{
  display: block;
  width: 24px;
  height: 24px;
  background-size: 24px;
  background-position: center;
  flex: none;
  margin: 4px;
  &._time{
    background: url(../img/Clock.svg) center no-repeat;
  }
  &._message{
    background: url(../img/comments/message.svg) center no-repeat;
  }
  &._document{
    background: url(../img/file.svg) center no-repeat;
  }
  &._check{
    background: url(../img/check-blue.svg) center no-repeat;
  }
  &._work{
    background: url(../img/pencil-ruler.svg) center no-repeat;
  }
  &._returned{
    background: url(../img/event-back-blue.svg) center no-repeat;
  }
}
